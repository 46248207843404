<template>
	<div class="c-app flex-row align-items-center">
		<CContainer>
			<CRow class="justify-content-center">
				<CCol md="8">
					<CCardGroup>
						<CCard class="p-4">
							<CCardBody>
								<CForm>
									<h1>Login</h1>
									<p class="text-muted">Sign In to your account</p>
									<CInput
										placeholder="Username"
										autocomplete="username email"
										v-model="username"
									>
										<template #prepend-content
											><CIcon name="cil-user"
										/></template>
									</CInput>
									<CInput
										placeholder="Password"
										type="password"
										v-model="password"
										autocomplete="curent-password"
									>
										<template #prepend-content
											><CIcon name="cil-lock-locked"
										/></template>
									</CInput>
									<CRow>
										<CCol col="6" class="text-left">
											<CButton @click="login" color="primary" class="px-4"
												>Login</CButton
											>
										</CCol>
										<CCol col="6" class="text-right">
											<CButton color="link" class="px-0"
												>Forgot password?</CButton
											>
											<CButton
												@click="$router.push({ name: 'Register' })"
												color="link"
												class="d-lg-none"
												>Register now!</CButton
											>
										</CCol>
									</CRow>
								</CForm>
							</CCardBody>
						</CCard>
						<CCard
							color="primary"
							text-color="white"
							class="text-center py-5 d-md-down-none"
							body-wrapper
						>
							<CCardBody>
								<h2>Sign up</h2>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
									do eiusmod tempor incididunt ut labore et dolore magna aliqua.
								</p>
								<CButton
									@click="$router.push({ name: 'Register' })"
									color="light"
									variant="outline"
									size="lg"
								>
									Register Now!
								</CButton>
							</CCardBody>
						</CCard>
					</CCardGroup>
				</CCol>
			</CRow>
		</CContainer>
	</div>
</template>

<script>
import { debounce } from "@/plugins/utils";
import { mapMutations, mapState } from "vuex";

export default {
	name: "Login",
	data() {
		return {
			password: "password",
			username: "mrjack",
			captcha: "",
			captchaLogin: "",
			loading: false,
			code: "",
		};
	},
	computed: {
		...mapState("appinfo", ["permissions", "name"]),
	},

	mounted() {
		if (this.permissions != "") {
			this.$router.push({ name: "Dashboard" });
		}
		//const data=await this.auth()
		//console.log(data);
		//if(data) this.$router.push({name:'Dashboard'})
		//this.$toast.error("I'm a toast!");
	},
	methods: {
		...mapMutations("appinfo", ["SET_NAME"]),
		login: debounce(async function () {
			this.$insProgress.start();
			try {
				const { data } = await this.$http.post(
					"login",
					{
						password: this.password,
						username: this.username,
						captcha: this.captchaLogin,
						code: this.code,
					},
					{ withCredentials: true }
				);
				if (data.status == "fail") {
					this.$toast.error(data.message);
				} else {
					let dataLocal = {
						name: data.data.name,
						token: data.data.token,
						email: data.data.email,
						username: data.data.username,
						role: data.data.role,
						permissions: data.data.permissions,
					};
					this.$toast.success(data.message);
					this.SET_NAME(dataLocal);
					this.$router.push({ name: "Dashboard" });
				}
			} catch (e) {
				this.$toast.error(e.message);
			}
			//this.$insProgress.finish()
		}, 700),
	},
};
</script>
